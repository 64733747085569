import React from "react"
import Layout from "../layout"
import Seo from "../seo"
import ImageCarousel from "../imageCarousel"
import AudioCarousel from "../audioCarousel"
import { withTrans } from "../../i18n/withTrans"
import i18next from "../../i18n/config"
import { Link } from "gatsby"

const Objekt = ({t, ...props}) => {
  const {
    pageContext: {
      node,
      next,
      previous,
      ...images
    },
  } = props
  const tr = (de,en)=>(i18next.language==="de"?de:en)

    return (
        <Layout>
            <Seo title={node.ausstellung + " " + node.quelltitel}/>
                <main id="maincontent">
                    <div style={{marginTop: "100px", marginBottom: "60px"}}>
                      { (!node.datatype || node.datatype !== "audio") &&
                        <ImageCarousel images={images} menge={node.menge}/>
                      }
                      { (node.datatype && node.datatype === "audio") &&
                        <AudioCarousel urls={node.objekturl} menge={node.menge}/>
                      }
                    </div>
                    <div style={{background: "#ffffff", padding:"20px"}}>
                        <div style={{paddingLeft:"max(calc(50vw - 250px), 20px)", paddingRight: "max(calc(50vw - 250px), 20px)", marginBottom:"0", paddingBottom:"50px", paddingTop:"50px"}}>
                          <div style={{position:"relative"}}>

                            <span className="p11B">{t("objekt.ausstellung")}</span>
                            <span className="p11G"> {tr(node.ausstellung, node.ausstellungEn)}</span><br/>
                            <span className="p11B">{t("objekt.jahr")}</span>
                            <span className="p11G"> {tr(node.jahr, node.jahrEn)}</span><br/>
                            <span className="p11B">{t("objekt.laufzeit")}</span>
                            <span className="p11G"> {tr(node.laufzeit, node.laufzeitEn)}</span><br/>
                            <span className="p11B">{t("objekt.ort")}</span>
                            <span className="p11G"> {tr(node.ort, node.ortEn)}</span><br/>
                            <span className="p11B">{t("objekt.urheber")}</span>
                            <span className="p11G"> {tr(node.urheber, node.urheberEn)}</span><br/>
                            <span className="p11B">{t("objekt.quelltitel")}</span>
                            <span className="p11G"> {tr(node.quelltitel, node.quelltitelEn)}</span><br/>
                            <span className="p11B">{t("objekt.quelljahr")}</span>
                            <span className="p11G"> {tr(node.quelljahr, node.quelljahrEn)}</span><br/>
                            <span className="p11B">{t("objekt.beschreibung")}</span>
                            <span className="p11G"> {tr(node.beschreibung, node.beschreibungEn)}</span><br/>
                            <span className="p11B">{t("objekt.material")}</span>
                            <span className="p11G"> {tr(node.material, node.materialEn)}</span><br/>
                            <span className="p11B">{t("objekt.inventar")}</span>
                            <span className="p11G"> {node.inventar}</span><br/>
                            <span className="p11B">{t("objekt.menge")}</span>
                            <span className="p11G"> {node.menge}</span><br/>


                            <br/>
                            <span className="p11B" style={{marginTop:"50px", display:"block"}}>
                            { previous &&
                              <Link to={ previous.ausstellung==="Quellentexte"?"/quellentexte/"+previous.id.toLowerCase().replace(/\W/g, '') :
                                  "/ausstellungen/"+previous.ausstellung.toLowerCase().replace(/\W/g, '')+"/"+previous.id.toLowerCase().replace(/\W/g, '')}
                                style={{textDecoration: "underline", color:"black", float:"left"}}>
                                  {t("objekt.prev")}
                              </Link>
                            }
                            { next &&
                              <Link to={next.ausstellung==="Quellentexte"?"/quellentexte/"+next.id.toLowerCase().replace(/\W/g, '') :
                                  "/ausstellungen/"+next.ausstellung.toLowerCase().replace(/\W/g, '')+"/"+next.id.toLowerCase().replace(/\W/g, '')}
                                style={{textDecoration: "underline", color:"black", float:"right"}}>
                                  {t("objekt.next")}
                              </Link>
                            }
                            </span>
                          </div>
                        </div>
                      </div>
                </main>
        </Layout>
    )
}

export default withTrans(Objekt)
