import React, { createRef } from 'react'

import AudioPlayer from "./audioplayer.js"
import { StaticImage } from "gatsby-plugin-image"

import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

function CustomNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className + " hoverIcon"}
      onClick={onClick}
      style={style}
    >
      <StaticImage
        src="../images/arrow_forward.png"
        style={{width:"min(50px, 10vw)", height:"50px"}}
        alt="search"
        placeholder="none"
        loading="eager"
      />
    </div>
  );
}

function CustomPrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className + " hoverIcon"}
      onClick={onClick}
      style={style}
    >
      <StaticImage
        src="../images/arrow_back.png"
        style={{width:"min(50px, 10vw)", height:"50px"}}
        alt="search"
        placeholder="none"
        loading="eager"
      />
    </div>
  );
}


const Carousel = ({urls, menge, ...props}) => {
  const elRefs = React.useRef([]);
  elRefs.current = Array(menge).fill().map((_, i) => elRefs.current[i] || createRef());
  const onChange = (index)=>{
    elRefs.current.forEach((item, i) => {
      item.current.pause();
      // if(i!=index){
      //   console.log(i, item)
      // }
      // elRefs.current[index].current.pause();
    });

  }
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    onInit:()=>{document.querySelector(".slick-slide").focus()},  // accessability
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    nextArrow: <CustomNextArrow/>,
    prevArrow: <CustomPrevArrow />,
    afterChange: onChange
  };
  return (
    <div style={{maxWidth: "min(80vw, 600px)", margin:"0 auto", display:"block",}}  className={menge !== 1 ? "grabbable" : ""}>
      <Slider {...settings}>
        {urls.split("\n").map( (source, index) => (
          <div key={index}>
            <AudioPlayer source={source} ref={elRefs.current[index]}/>
          </div>
        ))}
      </Slider>
    </div>
  )
}

export default Carousel
