import React, { createRef, setState } from "react"
import { StaticQuery, graphql } from "gatsby";

import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

export default class Player extends React.Component {
	constructor(props){
		super(props)
    this.canvas = React.createRef();
    this.player = React.createRef();
    this.state = {
      canvasCtx: null,
      analysing: false,
      analyser: null,
      bufferLength: 1024,
    };
    this.draw = this.draw.bind(this);
    this.pause = this.pause.bind(this);
	}

	componentDidMount(){}

	pause(){
		// try {
		// 	if(this.player.current.container.current.children[1].children[1].firstChild.children[1].attributes[0].value == "Pause"){
		// 			this.player.current.container.current.children[1].children[1].firstChild.children[1].click();
		// 	}
		// } catch (e) {}
		// //test 
	}

  draw(){
    try {
      let dataArray = new Uint8Array(1024);
      this.state.analyser.getByteTimeDomainData(dataArray);

      let h = this.canvas.current.clientHeight;
      let w = this.canvas.current.clientWidth;
      let ctx = this.state.canvasCtx;

      ctx.clearRect(0, 0, w, h);
      ctx.lineWidth = 2;
      ctx.strokeStyle = 'rgb(0, 0, 0)';
      ctx.beginPath();
      const sliceWidth = w / 1024;
      var x = 0;
      for(var i = 0; i < 1024; i++) {
             var v = dataArray[i] / 128.0;
             var y = v * (h)/2;
             y = h - y;
             if(i === 0) {ctx.moveTo(x, (h)/2);}
             else {ctx.lineTo(x, y);}
             x += sliceWidth;
       }
       ctx.lineTo(w, h/2);
       ctx.stroke();
    } catch (e) {}
    requestAnimationFrame(this.draw)
  }

	render() {
    return (
      <StaticQuery
        query={graphql`
          query audioQuery {
            allFile(filter: {extension: {eq: "mp3"}}) {
              edges {
                node {
                  publicURL
                  name
                }
              }
            }
          }
      `}
      render={(data) => { const result = data.allFile.edges.filter(edge => edge.node.name === this.props.source)[0]; return (
	      <>
	        <canvas ref={this.canvas} height="200" style={{width: "100%", height:"200px"}} />

	        <AudioPlayer
	          ref={this.player}
	          onLoadStart={()=>{
	            if(!this.state.analysing){
	              // only connect analyzer when first playing
	              this.setState({analysing: true});
	              // create audiocontext
	              const audioCtx = new(window.AudioContext || window.webkitAudioContext)();
	              // configure analyser
	              this.setState({analyser: audioCtx.createAnalyser()}, ()=>{
									audioCtx.createMediaElementSource(this.player.current.audio.current).connect(this.state.analyser);
									this.state.analyser.connect(audioCtx.destination);
		              this.state.analyser.fftSize = 1024;
									this.setState({bufferLength: this.state.analyser.frequencyBinCount}, ()=>{
										// start draw loop
									 this.setState({canvasCtx: this.canvas.current.getContext('2d')}, ()=>{
										 requestAnimationFrame(this.draw);
									 })
									})
								})
	            }
	          }}
	          style={{borderRadius: "8px"}}
	          layout="horizontal-reverse"
	          customControlsSection={["MAIN_CONTROLS", "VOLUME_CONTROLS"]}
	          src={result.node.publicURL}/>
	      </>
     )} }
     />
    )
	}
}




//
// const Player = ({ source, t, handler }) => {
//   const data = useStaticQuery(graphql`
//     query audioQuery {
//       allFile(filter: {extension: {eq: "mp3"}}) {
//         edges {
//           node {
//             publicURL
//             name
//           }
//         }
//       }
//     }
//   `)
//   const results = data.allFile.edges.filter(edge => source.split("\n").some(line=> edge.node.name == line))
//   //handle audio -> graphics
//   let player = null
//   let analysing = false
//   const audioCtx = new(window.AudioContext || window.webkitAudioContext)();
//   const analyser = audioCtx.createAnalyser();
//   analyser.fftSize = 2048;
//   const bufferLength = analyser.frequencyBinCount;
//   let dataArray = new Uint8Array(bufferLength);
//   //graphics
//   let canvas = null;
//   let canvasCtx = null;
//   let h,w = 0;
//   const draw = () => {
//     analyser.getByteTimeDomainData(dataArray);
//     if (canvas && canvasCtx) {
//       h = canvas.clientHeight
//       w = canvas.clientWidth
//       canvasCtx.clearRect(0, 0, w, h);
//       canvasCtx.lineWidth = 2;
//       canvasCtx.strokeStyle = 'rgb(0, 0, 0)';
//
//       canvasCtx.beginPath();
//
//       const sliceWidth = w / bufferLength;
//       var x = 0;
//       for(var i = 0; i < bufferLength; i++) {
//              var v = dataArray[i] / 128.0;
//              var y = v * (h)/2;
//              y = h - y;
//              if(i === 0) {canvasCtx.moveTo(x, (h)/2);}
//              else {canvasCtx.lineTo(x, y);}
//              x += sliceWidth;
//        }
//        canvasCtx.lineTo(w, h/2);
//        canvasCtx.stroke();
//     }
//     requestAnimationFrame(draw)
//   }
//
//   return(
//     <>
//       {results.length > 0 && results.map(file => (
//         <>
//           <canvas ref={(canRef)=>{
//             canvas = canRef;
//             if(canvas){
//               canvasCtx = canvas.getContext('2d');
//             }
//           }}
//           height="200"
//           style={{width: "100%", height:"200px"}}
//           />
//
//           <AudioPlayer
//             ref={(pl)=>{
//               player = pl;
//             }}
//             onPlay={(e) => {
//               // only connect analyzer when first playing
//               if(!analyzing && player){
//                 analysing = true;
//                 audioCtx.createMediaElementSource(player.audio.current).connect(analyser);
//                 analyser.connect(audioCtx.destination);
//                 requestAnimationFrame(draw)
//               }
//             }}
//             style={{borderRadius: "8px"}}
//             layout="horizontal-reverse"
//             customControlsSection={["MAIN_CONTROLS", "VOLUME_CONTROLS"]}
//             src={file.node.publicURL}/>
//         </>
//       ))}
//     </>
//   )
// }
//
//
// export default Player
